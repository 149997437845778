body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff; /* Set entire background to white */
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: #2c3e50;
}

p {
  margin: 0;
  color: #7f8c8d;
}

a {
  text-decoration: none;
  color: #3498db;
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.2em;
  }

  button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  body {
    font-size: 12px;
  }

  h1 {
    font-size: 1.2em;
  }

  h2 {
    font-size: 1em;
  }

  button {
    padding: 6px 12px;
  }
}
